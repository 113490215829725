import React from 'react';
import DonorLineItem from './donor';

const LoadingSkeleton = () => {
  return (
    <div className="animate-pulse my-3">
      <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
      <div className="h-2 bg-gray-200 rounded-full w-32 mb-2.5"></div>
      <div className="py-3"></div>
      <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
      <div className="h-2 bg-gray-200 rounded-full w-32 mb-2.5"></div>
      <div className="py-3"></div>
      <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
      <div className="h-2 bg-gray-200 rounded-full w-32 mb-2.5"></div>
    </div>
  );
};

const RecentDonationCard = ({ donations, customers, loading }) => {
  const getCustomer = (donation) => {
    return customers.find((customer) => customer.id === donation.customerId);
  };

  return (
    <div className="bg-primaryLight p-4 rounded-lg shadow-md">
      <div>
        <h3 className="mb-4 text-center md:text-left text-2xl font-heading font-semibold text-gray-900">
          Recent Donations
        </h3>
        <ul className="max-h-96 overflow-auto">
          {loading ? (
            <LoadingSkeleton />
          ) : (
            donations.map((donation) => (
              <DonorLineItem
                key={donation.id}
                donation={donation}
                customer={getCustomer(donation)}
              />
            ))
          )}
        </ul>
      </div>
    </div>
  );
};

export default RecentDonationCard;
