import React from 'react';
import { currencyFormatter, timeAgo } from '../../../util/helpers';

const DonorLineItem = ({ donation, customer }) => {
  return (
    <div className="flex gap-6 my-3 items-center font-semibold text-gray-800 rounded-md">
      <img
        className="w-10 h-10 rounded-full"
        src="https://www.svgrepo.com/show/143723/donation.svg"
        alt={'customer'}
      />
      <div className="flex flex-col">
        {customer ? (
          <div>
            {customer.givenName} {customer.familyName.slice(0, 1)}
          </div>
        ) : (
          <div>Anonymous donor</div>
        )}
        <div className="flex flex-row">
          <div className="text-sm ">
            <span className="font-bold">
              {currencyFormatter.format(donation.totalMoney.amount / 100)}
            </span>
            <span className="px-2 text-gray-400">•</span>
            <span className="text-gray-400 font-normal">
              {timeAgo.format(new Date(donation.createdAt))}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonorLineItem;
