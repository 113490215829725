import React from 'react';
import { currencyFormatter } from '../../../util/helpers';
import { PrimaryLink } from '../../primary-link';

const DonationCard = ({
  currentGoal,
  endGoal,
  totalDonations,
  donateLink,
  loading,
}) => {
  const goalProgressPercentage = Math.round((currentGoal / endGoal) * 100);

  return (
    <div className="bg-primaryLight p-4 rounded-lg shadow-md">
      <div>
        <h3 className="mb-4 text-2xl text-center font-heading font-semibold text-gray-900">
          Total Donations
        </h3>
        <div>
          <div className="flex justify-between mb-4 items-end">
            {loading ? (
              <div className="animate-pulse">
                <div className="h-10 bg-gray-200 rounded-full w-48"></div>
              </div>
            ) : (
              <span className="text-4xl font-bold text-secondaryDark">
                {currencyFormatter.format(currentGoal)}
              </span>
            )}

            <span className="text-lg font-bold text-gray-600">
              {currencyFormatter.format(endGoal)}
            </span>
          </div>
          {loading ? (
            <div className="animate-pulse">
              <div className="w-full h-8 bg-gray-200 rounded-full"></div>
            </div>
          ) : (
            <div className="w-full h-8 bg-gray-200 rounded-full">
              <div
                className="bg-secondary h-full text-lg font-bold text-black text-center p-0.5 rounded-l-full"
                style={{ width: `${goalProgressPercentage}%` }}
              >
                {goalProgressPercentage}%
              </div>
            </div>
          )}
        </div>
        <div className="mt-4 flex flex-col-reverse gap-4 md:flex-row items-center justify-between">
          {loading ? (
            <div className="animate-pulse">
              <div className="h-4 bg-gray-200 rounded-full w-32"></div>
            </div>
          ) : (
            <p className="text-md font-bold text-gray-600">
              {totalDonations ?? 0} online donations
            </p>
          )}

          <PrimaryLink text={donateLink.linkText} link={donateLink.linkUrl} />
        </div>
      </div>
    </div>
  );
};

export default DonationCard;
