import React, { useEffect, useState } from 'react';
import { Layout } from '../components/layout/layout';
import { PageHeading } from '../components/layout/page-heading';
import { graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { textOptions } from '../util/rich-text';
import DonationCard from '../components/cards/donations/donation-card';
import RecentDonationCard from '../components/cards/donations/recent-donation-card';

const Donations = ({ data }) => {
  const {
    title,
    heroText,
    heroSubtitleText,
    description,
    goal = 0,
    startingGoal = 0,
    donateLink,
    coverPhoto,
    shortDescription,
  } = data.contentfulDonations;
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [endGoal, setEndGoal] = useState(goal);
  const [currentGoal, setCurrentGoal] = useState(startingGoal);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetch('/.netlify/functions/get-square-orders')
      .then((response) => response.json())
      .then((response) => JSON.parse(response.data))
      .then((ordersResponse) => {
        if (ordersResponse != null && Array.isArray(ordersResponse)) {
          const schoolProjectOrders = ordersResponse.filter(
            (order) =>
              order.fulfillments &&
              order.fulfillments.length &&
              order.customerId &&
              order.lineItems.length &&
              order.lineItems.length > 0 &&
              (order.lineItems[0].name === 'LBYG School Project Donation 2021/22' ||
              order.lineItems[0].name === 'LBYG School Project 2024')
          );
          setOrders(schoolProjectOrders);
        }
      })
      .catch(() => setOrders([]))
      .finally(() => setLoading(false));

    fetch('/.netlify/functions/get-square-customers')
      .then((response) => response.json())
      .then((response) => JSON.parse(response.data))
      .then((customersResponse) => {
        if (customersResponse != null && Array.isArray(customersResponse)) {
          setCustomers(customersResponse);
        }
      })
      .catch(() => setCustomers([]));
  }, []);

  useEffect(() => {
    const totalInCents = orders.reduce(
      (acc, curr) => acc + curr.totalMoney.amount,
      0
    );
    setCurrentGoal((currTotal) => (currTotal += totalInCents / 100));
  }, [orders]);

  return (
    <Layout
      title={title}
      description={shortDescription.shortDescription}
      image={coverPhoto.fixed.src}
      isWide
    >
      <section>
        <div
          className="bg-cover bg-center xl:min-h-[calc(100vh/2)]"
          style={{ backgroundImage: `url(${coverPhoto.fixed.src})` }}
        >
          <div className="container flex flex-col items-center px-4 py-16 mx-auto text-center lg:pb-56 md:py-28 md:px-10 lg:px-32">
            <div className="flex flex-col items-center bg-slate-900 bg-opacity-25 p-4 md:p-8 lg:p-12 rounded-lg">
              <h3 className="mb-6 text-3xl font-bold text-primary uppercase">
                {heroSubtitleText}
              </h3>
              <h1 className="text-white text-4xl font-heading font-extrabold md:text-6xl">
                {heroText}
              </h1>
            </div>
          </div>
        </div>
        <div className="max-w-xl mx-4 md:mx-auto mb-8 -mt-10 lg:-mt-40">
          <DonationCard
            currentGoal={currentGoal}
            endGoal={endGoal}
            totalDonations={orders.length}
            donateLink={donateLink}
            loading={loading}
          />
        </div>
      </section>
      <div className="bg-white">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-0">
          <div className="max-w-2xl mx-auto md:py-8 lg:max-w-none">
            <PageHeading title={title} />
            <div className="mt-6">
              <div className="flex flex-col-reverse lg:flex-row gap-x-16">
                <div className="lg:w-3/4">
                  {description && renderRichText(description, textOptions)}
                </div>
                <div className="lg:w-1/4 lg:my-10">
                  <RecentDonationCard
                    donations={orders}
                    customers={customers}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Donations;

export const query = graphql`
  query {
    contentfulDonations {
      title
      heroText
      heroSubtitleText
      coverPhoto {
        fixed(width: 2000) {
          width
          height
          src
          srcSet
        }
      }
      shortDescription {
        shortDescription
      }
      description {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            description
            fixed(width: 1200) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
      startingGoal
      goal
      donateLink {
        linkText
        linkUrl
      }
    }
  }
`;
